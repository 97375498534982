<template>
  <div class="flex-1 df-middle">
    <div class="f-bold title-code mr-12">{{ prop('contactCode', order)}}</div>
    <t as="div" class="mr-12 f-medium f-nm"
      :class="order.result === 1 ? 'c-success' : 'c-danger'"
      :path="order.result === 1 ? 'quickplay_11' : 'quickplay_12'">
      {{order.result === 1 ? 'Take profit' : 'Stop loss'}}
    </t>
    <Money class="f-medium f-nm" #="{text, origin}" :v="prop('profit', order)">
      <ColorText :value="origin">{{text}}</ColorText>
    </Money>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Money from '@/components.global/Money'
import ColorText from '@/components/ColorText.vue'

export default defineComponent({
  name: 'TheFastHistoryHeader',
  components: { Money, ColorText },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
})
</script>

<style scoped>
  .title-code {
    min-width: 60px;
    max-width: 60px;
  }
</style>
