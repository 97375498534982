/**
* @author zjc[beica1@outook.com]
* @date 2021/8/19 17:01
* @description
*   ThePositionCountBadge.vue of WeTrade
*/
<template>
  <span class="badge ml-4" v-show="positionsCount">{{positionsCount}}</span>
</template>

<script lang="ts">
import { positionsCount } from '@/modules/fastTrade/fastTrade'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ThePositionCountBadge',
  setup () {
    return {
      positionsCount,
    }
  },
})
</script>

<style scoped>

</style>
