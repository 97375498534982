<template>
  <div class="d-f">
    <div class="flex-1">
      <p class="mb-8">
        <t path="quickplay_29" class="mr-4 c-title">Margin</t>
        <Money class="f-medium" :v="prop('amount', order)" />
        <Icon v-if="order.isJuan" name="coupon" class="c-title f-medium ml-4" sm />
      </p>
      <p class="mb-8">
        <t path="quickplay_30" class="mr-4 c-title">Profit</t>
        <Money class="f-medium" #="{text, origin}" :v="prop('profit', order)">
          <ColorText :value="origin">{{text}}</ColorText>
        </Money>
      </p>
      <p class="mb-8">
        <t path="quickplay_31" class="mr-4 c-title">T/P</t>
        <span class="f-medium">{{prop('stopProfitPrice', order)}}</span>
      </p>
      <p class="mb-8">
        <t path="quickplay_33" class="mr-4 c-title">Entry Price</t>
        <span class="f-medium">{{prop('createPrice', order)}}</span>
      </p>
      <p class="mb-8">
        <t path="quickplay_35" class="mr-4 c-title">Close Price</t>
        <span class="f-medium">{{prop('closePrice', order)}}</span>
      </p>
    </div>
    <div>
      <p class="mb-8">
        <t path="quickplay_28" class="mr-4 c-title">Direction</t>
        <t custom #="{td, t}">
          <ColorText class="f-medium" :display="[t('symbol_10') || 'Sell', t('symbol_9') || 'Buy']"
            :value="prop('buyType', order) - 2" />
        </t>
      </p>
      <p class="mb-8">
        <t path="quickplay_37" class="mr-4 c-title">Commission</t>
        <Money class="f-medium" :v="prop('fee', order)" />
      </p>
      <p class="mb-8">
        <t path="quickplay_32" class="mr-4 c-title">S/L</t>
        <span class="f-medium">{{prop('stopLossPrice', order)}}</span>
      </p>
      <p class="mb-8">
        <t path="quickplay_34" class="mr-4 c-title">Entry Time</t>
        <Time class="f-medium f-xs" format="MM-dd-yy HH:mm:ss" :time="prop('createTime', order)" />
      </p>
      <p class="mb-8">
        <t path="quickplay_36" class="mr-4 c-title">Close Time</t>
        <Time class="f-medium f-xs" format="MM-dd-yy HH:mm:ss" :time="prop('closeTime', order)" />
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Money from '@/components.global/Money'
import Time from '@/components/Time.vue'
import ColorText from '@/components/ColorText.vue'

export default defineComponent({
  name: 'TheFastHistoryContent',
  components: { Time, Money, ColorText },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
})
</script>
