<template>
  <div class="d-f">
    <div class="flex-1">
      <p class="mb-8">
        <t path="quickplay_19" class="mr-4 c-title">Margin</t>
        <Money class="f-medium" :v="prop('amount', order)" />
        <Icon v-if="order.isJuan" name="coupon" class="c-title f-medium ml-4" sm />
      </p>
      <p class="mb-8">
        <t path="quickplay_21" class="mr-4 c-title">T/P</t>
        <span class="f-medium">{{prop('stopProfitPrice', order)}}</span>
      </p>
      <p class="mb-8">
        <t path="quickplay_23" class="mr-4 c-title">Entry Price</t>
        <span class="f-medium">{{prop('createPrice', order)}}</span>
      </p>
      <p class="mb-8">
        <t path="quickplay_25" class="mr-4 c-title">Commission</t>
        <Money class="f-medium" :v="prop('fee', order)" />
      </p>
    </div>
    <div>
      <p class="mb-8">
        <t path="quickplay_18" class="mr-4 c-title">Direction</t>
        <t custom #="{td, t}">
          <ColorText class="f-medium" :display="[t('symbol_10') || 'Sell', t('symbol_9') || 'Buy']"
            :value="prop('buyType', order) - 2" />
        </t>
      </p>
      <p class="mb-8">
        <t path="quickplay_22" class="mr-4 c-title">S/L</t>
        <span class="f-medium">{{prop('stopLossPrice', order)}}</span>
      </p>
      <p class="mb-8">
        <t path="quickplay_24" class="mr-4 c-title">Entry Time</t>
        <Time class="f-medium f-xs" format="MM-dd-yy HH:mm:ss" :time="prop('createTime', order)" />
      </p>
    </div>
  </div>
  <p class="c-tip f-sm df-middle mb-8">
    <Icon name="help" sm class="mr-4" />
    <t path="quickplay_14">Commissions will only be charged when profit is made</t>
  </p>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Money from '@/components.global/Money'
import Time from '@/components/Time.vue'
import ColorText from '@/components/ColorText.vue'
import Icon from '@/components/Icon.vue'

export default defineComponent({
  name: 'TheFastPositionsContent',
  components: { Time, Money, ColorText, Icon },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
})
</script>
