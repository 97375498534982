/**
* 品种选择面板
* @author 贝才[beica1@outook.com]
* @date 2020/11/30
* @description
*   TheSymbolSelector.vue of FastTrade
*/
<template>
  <div class="selector df-middle px-16 bg-background" @click="isExpand = !isExpand">
    <CurrentQuote :code="product && product.contract" #="{symbol}">
      <RealTimeQuote #="{ price, mp, isRise, margin }" :symbol="symbol">
        <span class="white f-bold">{{symbol && symbol.code || '--'}}</span>
        <span class="flex-1 c-success" :class="{'c-danger': !isRise}">
        <span class="ml-16">
          {{price}}
          <Icon
            class="badge success p-0"
            :class="{danger: !isRise}"
            :name="isRise ? 'up' : 'down'" sm
            style="vertical-align: top"
          />
        </span>
        <span class="ml-16">{{margin}}</span>
        <span class="ml-16">{{mp}}</span>
      </span>
      </RealTimeQuote>
      <Icon name="caret-down" class="f-xl" />
    </CurrentQuote>
  </div>
  <teleport to="body">
    <transition name="slide-down">
      <div
        v-if="isExpand"
        class="spread p-a"
        @click="isExpand = !isExpand"
        style="padding: 96px 16px 0"
      >
        <div class="panel">
          <Matrix
            :list="products" :select="symbol"
            class="px-16 pt-16"
            :column="2" :col-gap="2" #="{ item, active }"
          >
            <ChoiceButton
              class="product" @click="product = item"
              :class="{active: product === item}"
            >
              <div class="p-r i-wrap df-middle df-center">
                <span>{{item.contract}}</span>
                <div class="close-state f-md df-middle" v-if="item.isClosed == 2">
                  closed
                </div>
              </div>
            </ChoiceButton>
          </Matrix>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script lang="ts">
import { marketFeed } from '@/common/datafeed'
import ChoiceButton from '@/components/ChoiceButton.vue'
import Icon from '@/components/Icon.vue'
import { ProductSchema, readProducts } from '@/modules/fastTrade/fastTrade.api'
import CurrentQuote from '@/provider/CurrentQuote.vue'
import RealTimeQuote from '@/provider/RealtimeQuote.vue'
import Matrix from 'common/Matrix.vue'
import * as R from 'ramda'
import { defineComponent, shallowRef, toRaw, watch } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent(
  {
    name: 'TheSymbolSelector',
    components: { CurrentQuote, ChoiceButton, Matrix, Icon, RealTimeQuote },
    props: {
      type: Number,
    },
    emits: ['change'],
    setup (props, ctx) {
      const route = useRoute()
      const isExpand = shallowRef(false)
      const products = shallowRef<ProductSchema[]>([])
      const product = shallowRef<ProductSchema | null>(null)

      watch(() => products.value, () => {
        product.value = R.find(
          R.propEq('contract', route.query.c ?? ''), products.value,
        ) ?? products.value[0]
      })

      watch(() => product.value, () => {
        if (product.value) {
          ctx.emit('change', toRaw(product.value))
        }
      })

      readProducts().then(resp => {
        products.value = resp
        marketFeed.subscribe(R.pluck('contract', resp))
      })

      return {
        products,
        product,
        isExpand,
      }
    },
  },
)
</script>

<style scoped lang="scss">
.selector {
  min-height: 48px;
}

.panel {
  background: white;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.3);
}

::v-deep(.product) {
  height: 36px;
  line-height: 28px;
}
::v-deep(.choice) {
  padding: 0 !important;
}
.i-wrap{
  height: 100%;
}
.close-state{
  position: absolute;
  top: -1px;
  left: -1px;
  color: var(--color-white);
  background: var(--color-border);
  border-top-left-radius: 6px;
  border-bottom-right-radius: 6px;
  height: 20px;
  padding: 0 4px;
}
</style>
