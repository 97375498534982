
import { defineComponent } from 'vue'
import Money from '@/components.global/Money'
import ColorText from '@/components/ColorText.vue'

export default defineComponent({
  name: 'TheFastPositionsHeader',
  components: { Money, ColorText },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
})
