<template>
  <div v-if="!read" class="user-guide px-16 py-8 br-lg f-md d-f">
    <p class="flex-1 d-f">
      <Pic src="fast_trade/description" width="16" height="16" />
      <t class="flex-1 ml-8" as="p" path="quickplay_50">
          Please note that Quick play is risky and is recommended only for entertainment, not as a formal investment.
      </t>
    </p>
    <div class="ml-16">
      <Icon name="close" class="close" @click="gotIt" />
    </div>
  </div>
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import { defineComponent, shallowRef } from 'vue'
import Pic from '@/components/Pic.vue'
import { keymap } from '@/config'

export default defineComponent({
  name: 'GuideContent',
  components: { Icon, Pic },
  setup () {
    const read = shallowRef(false)
    read.value = Boolean(sessionStorage.getItem(keymap.guide.quickPlayTips))

    const gotIt = () => {
      sessionStorage.setItem(keymap.guide.quickPlayTips, '1')
      read.value = true
    }
    return {
      read,
      gotIt,
    }
  },
})
</script>

<style scoped lang="scss">
.user-guide {
  color: var(--color-white);
  background: rgba(#181D24, 0.9);
  width: calc(100vw - 32px);
  position: fixed;
  top: 48px;
  left: 16px;
  z-index: 2;
}

.close {
  color: rgba(#181D24, 0.9);
  background: var(--color-white);
  border-radius: 50%;
}
</style>
