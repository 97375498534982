/**
* @author zjc[beica1@outook.com]
* @date 2021/8/17 23:39
* @description
*   ChoiceButton.vue of WeTrade
*/
<template>
  <div ref="choice" class="choice br-lg">
    <slot />
    <Icon class="c-i" name="check" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Icon from '@/components/Icon.vue'

export default defineComponent({
  name: 'ChoiceButton',
  components: {
    Icon,
  },
})
</script>

<style scoped lang="scss">
.choice {
  position: relative;
  padding: 5px 0;
  margin-bottom: 8px;
  border: 1px solid transparent;
  text-align: center;
  background: var(--color-light);
  .c-i{
    display: none;
  }

  &.active {
    color: var(--color-primary);
    border-color: var(--color-primary);
    background: #E6EDFE;
    .c-i{
      display: block;
      position: absolute;
      top: -8px;
      right: -8px;
      border-radius: 8px;
      font-size: 16px;
      width: 16px;
      height: 16px;
      line-height: 16px;
      font-family: tf, serif;
      color: white;
      background: var(--color-primary);
    }

    .price {
      color: var(--color-primary);
    }
  }
}
</style>
