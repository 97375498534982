
import { marketFeed } from '@/common/datafeed'
import ChoiceButton from '@/components/ChoiceButton.vue'
import Icon from '@/components/Icon.vue'
import { ProductSchema, readProducts } from '@/modules/fastTrade/fastTrade.api'
import CurrentQuote from '@/provider/CurrentQuote.vue'
import RealTimeQuote from '@/provider/RealtimeQuote.vue'
import Matrix from 'common/Matrix.vue'
import * as R from 'ramda'
import { defineComponent, shallowRef, toRaw, watch } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent(
  {
    name: 'TheSymbolSelector',
    components: { CurrentQuote, ChoiceButton, Matrix, Icon, RealTimeQuote },
    props: {
      type: Number,
    },
    emits: ['change'],
    setup (props, ctx) {
      const route = useRoute()
      const isExpand = shallowRef(false)
      const products = shallowRef<ProductSchema[]>([])
      const product = shallowRef<ProductSchema | null>(null)

      watch(() => products.value, () => {
        product.value = R.find(
          R.propEq('contract', route.query.c ?? ''), products.value,
        ) ?? products.value[0]
      })

      watch(() => product.value, () => {
        if (product.value) {
          ctx.emit('change', toRaw(product.value))
        }
      })

      readProducts().then(resp => {
        products.value = resp
        marketFeed.subscribe(R.pluck('contract', resp))
      })

      return {
        products,
        product,
        isExpand,
      }
    },
  },
)
