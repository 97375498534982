<template>
  <div class="guide-con t-text mb-20 f-nm">
    <div v-if="s === 1">
      <t path="quickplay_45" multiline #="{td}" custom>
        <p v-for="(p, index) in td" :key="index">{{p}}</p>
      </t>
    </div>
    <div v-else-if="s === 2">
      <Pic src="fast_trade/guide/Guide1" class="mb-10" />
      <t as="p" path="quickplay_46">1. Select the asset you want to trade.</t>
    </div>
    <div v-else-if="s === 3">
      <Pic src="fast_trade/guide/Guide2" class="mb-10" />
      <t as="p" path="quickplay_47">
        2. You can see the Take-profit and Stop-loss lines from the chart, the Take-profit and Stop-loss are fixed at +100% and -100%.
      </t>
    </div>
    <div v-else-if="s === 4">
      <Pic src="fast_trade/guide/Guide3" class="mb-10" />
      <t as="p" path="quickplay_48">
        3. Click the "+" or "-" button to increase or decrease the amount, or click the amount area to open the panel for selecting the amount.
      </t>
    </div>
    <div v-else-if="s === 5">
      <Pic src="fast_trade/guide/Guide4" class="mb-10" />
      <t as="p" path="quickplay_49">
        4. You can see the position from the chart, just wait for the price to touch the take-profit or stop-loss.
      </t>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Pic from '@/components/Pic.vue'

export default defineComponent({
  name: 'TheGuideContent',
  components: { Pic },
  props: {
    s: {
      type: Number,
      default: 1,
    },
  },
})
</script>

<style scoped lang="scss">
  .guide-con {
    line-height: 24px;

    img {
      width: 100%;
      display: block;
    }
  }
</style>
