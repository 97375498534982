
import Icon from '@/components/Icon.vue'
import { defineComponent, ref } from 'vue'
import TheGuideContent from '@/modules/fastTrade/components/TheGuideContent.vue'

export default defineComponent({
  name: 'TheGuideDialog',
  components: { TheGuideContent, Icon },
  setup (props, ctx) {
    const s = ref(1)

    const total = 5

    const preStep = () => {
      s.value = s.value === 1 ? 1 : s.value - 1
    }

    const nextStep = () => {
      s.value = s.value + 1
      if (s.value > total) {
        ctx.emit('close')
      }
    }
    return {
      s,
      total,
      nextStep,
      preStep,
    }
  },
})
