<template>
  <div class="flex-1 df-middle">
    <div class="f-bold title-code mr-12">{{ prop('contactCode', order)}}</div>
    <t custom #="{td, t}">
      <ColorText class="f-medium mr-12 f-nm" :display="[t('symbol_10') || 'Sell', t('symbol_9') || 'Buy']"
        :value="prop('buyType', order) - 2" />
    </t>
    <Money class="f-medium f-nm" #="{text, origin}" :v="prop('amount', order)">
      <ColorText :value="prop('buyType', order) - 2">{{text}}</ColorText>
    </Money>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Money from '@/components.global/Money'
import ColorText from '@/components/ColorText.vue'

export default defineComponent({
  name: 'TheFastPositionsHeader',
  components: { Money, ColorText },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
})
</script>

<style scoped>
  .title-code {
    min-width: 60px;
    max-width: 60px;
  }
</style>
