/**
* @author zjc[beica1@outook.com]
* @date 2021/8/16 14:29
* @description
*   History.vue of WeTrade
*/
<template>
  <Scroller @bottomTouched="loadMore" class="spread px-16 pt-6 mb-8">
    <Holder :content="historyGroup" :skeleton-repeat="2">
      <Nullable :content="historyGroup">
        <template v-for="(list, date) in historyGroup">
          <div v-if="date !== today" :key="date" class="t-center my-12 f-md c-tip">
            -- {{date}} --
          </div>
          <TheOrderItem v-for="item in list" :key="item.id">
            <template #header>
              <TheFastHistoryHeader :order="item" />
            </template>
            <TheFastHistoryContent :order="item" />
          </TheOrderItem>
        </template>
      </Nullable>
    </Holder>
  </Scroller>
</template>

<script lang="ts">
import TheOrderItem from '@/modules/fastTrade/components/TheOrderItem.vue'
import { defineComponent, computed, onActivated } from 'vue'
import Scroller from 'common/providers/Scroller.vue'
import Holder from '@/provider/Holder.vue'
import useLoadMore from 'common/hooks/useLoadMore'
import { FastOrderSchema, readFastHistory } from '@/modules/fastTrade/history.api'
import TheFastHistoryContent from '@/modules/fastTrade/components/TheFastHistoryContent.vue'
import TheFastHistoryHeader from '@/modules/fastTrade/components/TheFastHistoryHeader.vue'
import { formatDateTime } from '@/common/format'
import * as R from 'ramda'
import { defaultToArray } from '@/common/common'
import Nullable from '@/components/Nullable.vue'

export default defineComponent({
  name: 'History',
  components: { Nullable, TheFastHistoryHeader, TheFastHistoryContent, TheOrderItem, Holder, Scroller },
  setup () {
    const { loaded: history, refresh, loadMore } = useLoadMore<FastOrderSchema, Array<FastOrderSchema>>(
      readFastHistory, 20, resp => resp,
    )

    const today = formatDateTime('MM-dd-yy', new Date().getTime())

    refresh()

    const groupHistory: ((orders: FastOrderSchema[]) => Record<string, FastOrderSchema[]>) = R.pipe(
      defaultToArray,
      R.map((order: FastOrderSchema) => R.assoc('day', formatDateTime('MM-dd-yy', R.prop('createTime', order)), order)),
      R.groupBy(R.prop('day')),
    )

    const historyGroup = computed((): Record<'day', FastOrderSchema[]> | null => {
      if (history.value) return groupHistory(history.value)
      return null
    })

    onActivated(refresh)

    return {
      loadMore,
      today,
      historyGroup,
    }
  },
})
</script>
