/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/17
* @description
*   TheAsset.vue of WeTrade
*/
<template>
  <slot v-bind="account" :free="free">
    <money :v="free" />
  </slot>
</template>

<script lang="ts">
import * as R from 'ramda'
import { computed, defineComponent } from 'vue'
import state from '@/state'

export default defineComponent({
  name: 'TheAsset',
  setup () {
    return {
      account: computed(() => state.account),
      free: computed(() => R.min(state.account.balance, state.account.freeMargin)),
    }
  },
})
</script>
