/**
* @author zjc[beica1@outook.com]
* @date 2021/8/16 14:28
* @description
*   TheOrderItem.vue of WeTrade
*/
<template>
  <div class="mb-8 history">
    <div class="history-title pl-12 pr-8 f-md df-middle" @click="isShow">
      <slot name="header"></slot>
      <Icon
        :name="show ? 'caret-up' : 'caret-down'"
        class="ml-8 list-arrow" sm
      />
    </div>
    <div v-show="show" class="history-detail f-sm pl-12 pr-8 pt-12 pb-4">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import Icon from '@/components/Icon.vue'

export default defineComponent({
  name: 'TheOrderItem',
  components: { Icon },
  setup () {
    const show = ref(false)

    const isShow = () => {
      show.value = !(show.value)
    }

    return {
      show,
      isShow,
    }
  },
})
</script>

<style scoped lang="scss">
.history {
  border-radius: 2px;
  overflow: hidden;
}

.history-title {
  height: 40px;
  background: var(--color-light);
}

.list-arrow {
  vertical-align: top;
  position: relative;
  top: 1px;
}

.history-detail {
  border: 1px solid #D5DEE8;
  border-top: none;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}

</style>
