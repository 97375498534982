
import T from '@/components.global/T.vue'
import Line from '@/components/Line.vue'
import { openDialog } from '@/components/popup/popup'
import WTTabs from '@/components/Tabs.vue'
import { events, keymap } from '@/config'
import { refreshAccount } from '@/modules/app/app'
import TheChart from '@/modules/fastTrade/components/TheChart.vue'
import TheGuideDialog from '@/modules/fastTrade/components/TheGuideDialog.vue'
import ThePositionCountBadge from '@/modules/fastTrade/components/ThePositionCountBadge.vue'
import TheSymbolSelector from '@/modules/fastTrade/components/TheSymbolSelector.vue'
import TheTradePanel from '@/modules/fastTrade/components/TheTradePanel.vue'
import TheFastPlayTips from '@/modules/fastTrade/components/TheFastPlayTips.vue'
import {
  calcStops,
  dropUnusedOrderColor,
  generateProducts,
  Product,
  updatePositionsCount,
} from '@/modules/fastTrade/fastTrade'
import { ProductSchema } from '@/modules/fastTrade/fastTrade.api'
import { FastOrderSchema, readFastPositions } from '@/modules/fastTrade/history.api'
import History from '@/modules/fastTrade/History.vue'
import Positions from '@/modules/fastTrade/Positions.vue'
import TheAsset from '@/modules/user/components/TheAsset.vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import { TradeDirection } from '@/types'
import DemoAccountTip from '@/views/DemoAccountTip.vue'
import { localGet, localSet } from 'essential/store/localStore'
import { emit, off, on } from 'essential/tools/event'
import * as R from 'ramda'
import { defineComponent, h, onBeforeUnmount, shallowRef, watchEffect } from 'vue'

export default defineComponent({
  name: 'FastTrade',
  components: {
    Line,
    History,
    Positions,
    TheTradePanel,
    WTTabs,
    DemoAccountTip,
    TheChart,
    TheSymbolSelector,
    TheAsset,
    PageWithHeader,
    TheFastPlayTips,
  },
  setup () {
    const positions = shallowRef<FastOrderSchema[]>([]) // 记录持仓
    const code = shallowRef('') // symbol
    const stops = shallowRef<{ profit: number, loss: number } | null>(null) // 止盈/止损范围
    const tradeType = shallowRef(TradeDirection.BUY) // 做单方向
    const products = shallowRef<Product[]>([])

    const positionTab = h('span', [
      h(T, { path: 'quickplay_5' }),
      h(ThePositionCountBadge),
    ])

    // 更新当前品种的持仓单
    const updateHoldPositions = () => {
      readFastPositions().then(resp => {
        positions.value = R.filter(R.propEq('contactCode', code.value), resp)
        // 去除多余的订单颜色信息
        dropUnusedOrderColor(R.pluck('orderId', resp))
      })
    }

    watchEffect(() => {
      if (code.value) {
        updateHoldPositions()
      }
    })

    watchEffect(() => {
      updatePositionsCount(positions.value.length)
    })

    const showGuide = () => {
      openDialog(TheGuideDialog, { wrapperClass: 'center' }, { transition: 'slide-down' })
    }

    if (!localGet(keymap.guide.fastGuideDialog)) {
      showGuide()
      localSet(keymap.guide.fastGuideDialog, '1')
    }

    const onCreated = (order: FastOrderSchema) => {
      positions.value = positions.value.concat(order)
    }

    const onProductChange = (product: ProductSchema) => {
      code.value = product.contract
      products.value = generateProducts(product)
      emit(events.fastTradeProductChange, products.value)
      stops.value = calcStops(product)
    }

    const onTypeChange = (_type: TradeDirection) => {
      tradeType.value = _type
    }

    const onPositionStop = (orderId: number) => {
      positions.value = R.reject(R.propEq('orderId', orderId), positions.value)
      refreshAccount()
    }

    on(events.quickClose, onPositionStop)

    onBeforeUnmount(() => {
      off(events.quickClose, onPositionStop)
    })

    return {
      positionTab,
      products,
      positions,
      code,
      stops,
      tradeType,
      onCreated,
      showGuide,
      onProductChange,
      onTypeChange,
    }
  },
})
