/**
* @author zjc[beica1@outook.com]
* @date 2021/8/18 0:15
* @description
*   Row.vue of WeTrade
*/
<template>
<div class="row mt-16 df-middle">
  <div class="flex-1">
    <slot name="label"></slot>
  </div>
  <div class="flex-3">
    <slot></slot>
  </div>
</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TheRowTemplate',
})
</script>

<style scoped>

</style>
