
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'
import Line from '@/components/Line.vue'
import { showAlert, openDialog } from '@/components/popup/popup'
import { events } from '@/config'
import useRequest from '@/hooks/useRequest'
import { translate } from '@/i18n'
import TheFiniteSelector from '@/modules/fastTrade/components/TheFiniteSelector.vue'
import TheRowTemplate from '@/modules/fastTrade/components/TheRowTemplate.vue'
import { Product } from '@/modules/fastTrade/fastTrade'
import { create } from '@/modules/fastTrade/fastTrade.api'
import CurrentQuote from '@/provider/CurrentQuote.vue'
import RealTimeQuote from '@/provider/RealtimeQuote.vue'
import { on } from 'essential/tools/event'
import { add } from 'essential/tools/math'
import { computed, defineComponent, shallowRef, watchEffect } from 'vue'
import { isDemoAccount } from '@/state/accountType'
import state from '@/state'
import { TradeDirection, YesOrNo } from '@/types'
import LimitDialogTemplate from '@/modules/membership/LimitDialogTemplate.vue'

export default defineComponent({
  name: 'TheTradePanel',
  components: {
    RealTimeQuote,
    CurrentQuote,
    Button,
    TheFiniteSelector,
    TheRowTemplate,
    Icon,
    Line,
  },
  emits: ['created', 'typeChanged'],
  setup (props, ctx) {
    const type = shallowRef(TradeDirection.BUY)
    const product = shallowRef<Product | null>(null)
    const products = shallowRef<Product[]>([])
    // const isPrime = computed(() => state.prime.member === YesOrNo.YES)

    const isBuy = computed(() => type.value === TradeDirection.BUY)

    on(events.fastTradeProductChange, (_products: Product[]) => {
      products.value = _products
    })

    const buy = () => {
      type.value = TradeDirection.BUY
    }

    const sell = () => {
      type.value = TradeDirection.SELL
    }

    watchEffect(() => {
      ctx.emit('typeChanged', type.value)
    })

    const [_commit, progress] = useRequest(create)

    const commit = () => {
      // if (!isDemoAccount.value && !isPrime.value) {
      //   openDialog(LimitDialogTemplate, {
      //     i18nPath: 'quickplay_52',
      //   })
      //   return false
      // } else {
      //   return _commit({
      //     code: product.value?.code,
      //     price: product.value?.price,
      //     voucher: 0,
      //     type: type.value,
      //   }).then(resp => {
      //     const pointsDesc = resp.rewardPoints ? ` [+${resp.rewardPoints}pts]` : ''
      //     showAlert(translate('quickplay_16', 'The order has been placed') + pointsDesc)
      //     ctx.emit('created', resp)
      //   })
      // }
      return _commit({
        code: product.value?.code,
        price: product.value?.price,
        voucher: 0,
        type: type.value,
      }).then(resp => {
        const pointsDesc = resp.rewardPoints ? ` [+${resp.rewardPoints}pts]` : ''
        showAlert(translate('quickplay_16', 'The order has been placed') + pointsDesc)
        ctx.emit('created', resp)
      })
    }

    const calcStopPrice = (price: string, profit = false) => {
      const diff = profit ? product.value?.stopProfitOffset : -(product.value?.stopLossOffset ?? 0)
      return add(price, (diff || 0) * (isBuy.value ? 1 : -1))
    }

    return {
      isBuy,
      buy,
      sell,
      product,
      products,
      commit,
      progress,
      calcStopPrice,
    }
  },
})
