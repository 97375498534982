
import TheOrderItem from '@/modules/fastTrade/components/TheOrderItem.vue'
import { defineComponent, onActivated } from 'vue'
import Scroller from 'common/providers/Scroller.vue'
import Holder from '@/provider/Holder.vue'
import useLoadMore from 'common/hooks/useLoadMore'
import { FastOrderSchema, readFastPositions } from '@/modules/fastTrade/history.api'
import TheFastPositionsContent from '@/modules/fastTrade/components/TheFastPositionsContent.vue'
import TheFastPositionsHeader from '@/modules/fastTrade/components/TheFastPositionsHeader.vue'
import List from '@/components/AsyncNullableList.vue'

export default defineComponent({
  name: 'Positions',
  components: { List, TheFastPositionsHeader, TheFastPositionsContent, TheOrderItem, Holder, Scroller },
  setup () {
    const { loaded, refresh, loadMore } = useLoadMore<FastOrderSchema, Array<FastOrderSchema>>(
      readFastPositions, 20, resp => resp,
    )

    refresh()

    onActivated(refresh)

    return {
      loadMore,
      loaded,
    }
  },
})
